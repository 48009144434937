<template>
    <div class="c-loader">
        <span class="c-loader_bg"></span>
        <div class="c-loader_inner">
            <span
                ref="bar"
                class="c-loader_bar"
            >
                <span
                    class="c-loader_progress"
                    :style="`transform: scaleX(${percentage.value/100})`"
                ></span>
            </span>
            <span
                class="c-loader_percentage"
                :class="{ 'is-visible' : percentageVisible }"
            >
                <span>
                    {{ Math.floor(percentage.value) }}%
                </span>
            </span>
            <p class="c-loader_states">
                <anim-text
                    v-for="(state, i) in states"
                    :key="`loader-state-${i}`"
                    tag="span"
                    :text="state.label"
                    :reveal="false"
                    :visible="state.visible"
                    class="c-loader_state"
                />
                <app-button
                    v-if="!showAgeForm"
                    label="Start"
                    display="primary"
                    :stretch="true"
                    :sound="false"
                    class="c-loader_start"
                    :class="{ 'is-visible' : showStartButton}"
                    @click="resumeAnimation()"
                />
            </p>
        </div>
        <age-form
            v-if="showAgeForm"
            ref="ageForm"
            @update="endLoad"
        />
    </div>
</template>

<script>

import AgeForm      from '@/templates/objects/AgeForm'
import AnimText     from '@/templates/objects/AnimText'
import AppButton    from '@/templates/components/AppButton'

import { gsap }     from '@/gsap'
import Cookies      from 'js-cookie'

export default {
    name: 'Loader',
    inject: ['store'],
    components: {
        AgeForm,
        AnimText,
        AppButton,
    },
    data: () => ({
        percentage: {
            value: 0
        },
        percentageVisible: false,
        states: [
            {
                label: '[receiving transmission...]',
                visible: false
            },
            {
                label: '[initiate handshake protocol...]',
                visible: false
            },
            {
                label: '[wait....]',
                visible: false
            },
            {
                label: '[the doctor is here]',
                visible: false
            },
            {
                label: '[all systems go for dab]',
                visible: false
            },
        ],
        showAgeForm: true,
        showStartButton: false,
    }),
    created() {
        this.showAgeForm = Cookies.get('ageFormValidated') !== 'true'
    },
    mounted() {

        this.tl = gsap.timeline({
            defaults: {
                duration: .6,
                ease: 'power2.inOut'
            },
        })

        // Start load animation
        this.startLoad()
    },
    methods: {

        startLoad() {

            this.tl
                .to(this.percentage, {
                    value: this._getRandomInt(10, 20),
                    onStart: () => {
                        this.percentageVisible = true
                        this.states[0].visible = true
                    }
                })
                .to(this.percentage, {
                    value: this._getRandomInt(35, 45),
                    delay: .3,
                    onStart: () => {
                        this.states[1].visible = true
                    }
                })
                .to(this.percentage, {
                    value: this._getRandomInt(60, 65),
                    delay: .3,
                    onStart: () => {
                        this.states[2].visible = true
                    }
                })
                .to(this.percentage, {
                    value: this._getRandomInt(75, 85),
                    delay: .3,
                    onStart: () => {
                        this.states[3].visible = true
                    },
                    onComplete: () => {
                        if(this.showAgeForm) {
                            this.$refs.ageForm.show()
                        } else {
                            this.endLoad(true)
                        }
                    }
                })
        },

        endLoad(ofAge) {

            this.store.setIsOfAge(ofAge)

            if(ofAge) {
                Cookies.set('ageFormValidated', true)
            }

            this.tl
                .to(this.percentage, {
                    value: 100,
                    onStart: () => {

                        this.states[4].visible = true

                        if(this.showAgeForm) {
                            this.$refs.ageForm.hide()
                        }
                    }
                })
                .add(() => {

                    if(!this.showAgeForm) {
                        this.showStartButton = true
                        this.tl.pause()
                    }
                })
                .to(this.$refs.bar, {
                    scaleX: 2,
                    duration: 1,
                    ease: 'power2.inOut',
                    delay: .3,
                    onStart: () => {
                        this.percentageVisible = false
                        this.showStartButton = false
                        for(let index in this.states) {
                            this.states[index].visible = false
                        }
                    },
                    onComplete: () => {

                        if(ofAge) {
                            this.store.firstloaded()
                        } else {
                            gsap.to(this.$refs.bar, {
                                opacity: 0,
                                duration: .3,
                                ease: 'power.out',
                                onComplete: () => {
                                    this.store.firstloaded()
                                    this.$router.push({ path: '/play' })
                                }
                            })
                        }
                    }
                })
        },

        resumeAnimation() {
            this.tl.play()
        },

        /**
         * Returns a random integer between min (inclusive) and max (inclusive).
         * The value is no lower than min (or the next integer greater than min
         * if min isn't an integer) and no greater than max (or the next integer
         * lower than max if max isn't an integer).
         * Using Math.round() will give you a non-uniform distribution!
         */
        _getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }
    },
}

</script>

<style lang="scss">

.c-loader {
    z-index: 200;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 1.2;
    font-size: 12px;

    .o-age-form {
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.c-loader_bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-darkest;
    transform-origin: 50% 100%;
}

.c-loader_inner {
    position: absolute;
    top: 50%;
    left: 25%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 50%;
}

.c-loader_bar {
    display: block;
    width: 100%;
    height: 1px;
    margin-bottom: 1em;
    background-color: $color-gray;
}

.c-loader_progress {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-primary;
    transform-origin: 0 50%;
    transition: transform .1s;
}

.c-loader_percentage {
    display: inline-block;
    overflow: hidden;

    span {
        display: block;
        transform: translate(0, 100%);
        transition: transform .4s $easing;
    }

    &.is-visible {

        span {
            transform: translate(0);
        }
    }
}

.c-loader_states {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    text-transform: uppercase;
}

.c-loader_start {
    margin-top: rem(12px);
    opacity: 0;
    transform: translate(0, 50%);
    transition: opacity .4s $easing, transform .4s $easing;

    &.is-visible {
        opacity: 1;
        transform: translate(0);
        transition: opacity .8s $easing, transform .8s $easing;
    }
}

</style>
