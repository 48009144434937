<template>
    <laser ref="laser" />
    <div class="c-scene">
        <canvas
            ref="canvas"
            class="c-scene_canvas"
            role="img"
            :aria-label="ariaLabel"
        ></canvas>
    </div>
</template>

<script>

import Laser from '@/templates/objects/Laser'

import { gsap }         from '@/gsap'
import * as THREE       from 'three';
import { GLTFLoader }   from 'three/examples/jsm/loaders/GLTFLoader'
import { store }        from '@/store'
// import oc from 'three-orbit-controls'

// import * as dat from 'dat.gui';
// import datGuiImage from 'dat.gui.image';
// datGuiImage(dat);


// const OrbitControls = oc(THREE);

export default {
    name: 'Scene',
    inject: ['store'],
    components: {
        Laser,
    },
    provide: {
        store
    },
    data: () => ({
        currentHover: null,
        currentIndex: -1,
        currentObjectId: false,
        objectAreDisplayed: false,
        sizes: {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }),
    created() {

        // Create object array
        this.objects = this.store.state.products.map(product => ({
            id: product.id,
            path: product.objectPath,
            timelines: null,
        }))

        this.setObjectsPositions()

    },

    mounted() {

        // Scene
        this.scene = new THREE.Scene();

        // Render
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            canvas: this.$refs.canvas,

            // preserveDrawingBuffer to allow screenshot
            preserveDrawingBuffer: true
        });
        this.renderer.setSize(this.sizes.width, this.sizes.height);
        this.renderer.outputEncoding = THREE.sRGBEncoding;

        // Camera
        this.initCamera();

        // Lights
        this.initLights();

        // Meshes
        this.initMeshes();

        // Mouse handle
        this.worldMousePosition = new THREE.Vector3(0,0,0);
        window.addEventListener('mousemove', this.onMouseMove = e => this.mousemove(e), false );

        // Trigger resize
        this.resize();

        // Tick render
        this.tick();

        window.addEventListener('resizeEnd', this.onResize = () => this.resize());

        /*** ADDING SCREEN SHOT ABILITY ***/
        if(!this.store.isProd) {
            window.addEventListener('keyup', (e) => {

                // Listen to 'P' key
                if(e.which !== 80) {
                    return
                }

                this.renderer.setClearColor(0x000000, 0);

                try {
                    const imgData = this.renderer.domElement.toDataURL();

                    const win = window.open();
                    win.document.write('<iframe src="' + imgData  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
                }
                catch(e) {
                    console.log('Browser does not support taking screenshot of 3d context', e);
                    return;
                }
            });
        }
    },

    methods: {

        initCamera() {
            this.camera = new THREE.PerspectiveCamera(75, this.sizes.width / this.sizes.height, 0.1, 1000);
            this.camera.position.z = 8;

            // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
            // this.controls.enableDamping = true;
            // this.controls.dampingFactor = 0.2;
            // this.controls.rotateSpeed = 0.4;
            // this.controls.enablePan = false;
        },

        initLights() {
            const ambientLight = new THREE.AmbientLight(0x404040); // soft white light
            this.scene.add(ambientLight);

            this.pointLight = new THREE.PointLight(0xffffff, 0.6, 15);
            this.pointLight.position.set(20, 20, 20);
            this.scene.add(this.pointLight);

            this.backLight = new THREE.PointLight(0xffffff, 0.6, 15);
            this.backLight.position.set(-20, -20, -20);
            this.scene.add(this.backLight);
        },

        initMeshes() {
            this.loadMeshes();
            this.createBeams();
        },

        setObjectsPositions() {

            let position, rotation
            this.objects.forEach((o, i) => {

                if(i === 0) {
                    position = {
                        base: new THREE.Vector3(
                            -4.5 * this.ratio.x,
                            -0.25 * this.ratio.y,
                            0
                        ),
                        hover: new THREE.Vector3(
                            -3.5 * this.ratio.x,
                            -0.25 * this.ratio.y,
                            1 * this.ratio.x
                        )
                    }
                    rotation = {
                        base: new THREE.Vector3(
                            0,
                            0,
                            1/12 * -Math.PI
                        ),
                        hover: new THREE.Vector3(
                            0,
                            -0.6 * -Math.PI,
                            -1/12 * -Math.PI
                        )
                    }
                } else if(i === 1) {
                    position = {
                        base: new THREE.Vector3(
                            0.001 * this.ratio.x,
                            -0.25 * this.ratio.y,
                            0
                        ),
                        hover: new THREE.Vector3(
                            0,
                            -0.25 * this.ratio.y,
                            1 * this.ratio.x
                        )
                    }
                    rotation = {
                        base: new THREE.Vector3(
                            0,
                            0.25 * Math.PI,
                            0
                        ),
                        hover: new THREE.Vector3(
                            0,
                            2/3 * Math.PI,
                            1/6 * Math.PI
                        )
                    }

                } else if(i === 2) {
                    position = {
                        base: new THREE.Vector3(
                            3.75 * this.ratio.x,
                            -0.25 * this.ratio.y,
                            0.25 * this.ratio.x
                        ),
                        hover: new THREE.Vector3(
                            2.75 * this.ratio.x,
                            -0.25 * this.ratio.y,
                            1.25 * this.ratio.x
                        )
                    }
                    rotation = {
                        base: new THREE.Vector3(
                            0,
                            0.5 * Math.PI,
                            0
                        ),
                        hover: new THREE.Vector3(
                            0.5 * Math.PI,
                            0.5 * Math.PI,
                            -0.25 * Math.PI
                        )
                    }
                }

                o.position = position
                o.rotation = rotation

                o.scale = 0.75 * this.ratio.xy
            })
        },

        createBeams() {
            this.redLight = new THREE.PointLight( 0xff0000, 1, 5);
            this.redLight.position.set(-1, 0, 0);
            this.redLight.intensity = 0;

            this.blueLight = new THREE.PointLight( 0x00E2ED, 1, 5);
            this.blueLight.position.set(1, 0, 0);
            this.blueLight.intensity = 0;

            this.beamsWrapper = new THREE.Object3D();
            this.beamsWrapper.add(this.redLight);
            this.beamsWrapper.add(this.blueLight);

            this.beamsWrapper.position.set(0,0,4);

            this.scene.add(this.beamsWrapper);
        },

        loadMeshes() {

            // Loaders
            const textureLoader = new THREE.TextureLoader();
            const objectLoader = new GLTFLoader();

            const material = new THREE.MeshPhongMaterial({
                color: 0x0f0f0f,
                specular: 0xffffff,
                reflectivity: 1,
                shininess: 100,
                side: THREE.DoubleSide,
            });

            textureLoader.load('/img/texture.jpg', texture => {
                texture.encoding = THREE.sRGBEncoding
                texture.mapping = THREE.EquirectangularReflectionMapping
                material.envMap = texture

                let loadedObjects = 0

                this.objects.forEach(o => {
                    objectLoader.load(o.path, data => {
                        data.scene.traverse(node => {
                            node.material = material
                        })

                        o.loadedData = data

                        const wrapper = new THREE.Object3D()
                        wrapper.add(data.scene)
                        o.scene = wrapper

                        this.scene.add(wrapper)

                        loadedObjects++

                        // When all objects are loaded, set timelines and dispatch sceneIsLoaded to true
                        if (loadedObjects === this.totalObjects) {
                            this.setTimelines()
                            this.store.sceneLoaded()
                        }

                    },
                    // // called while loading is progressing
                    // (xhr) => {
                    //     console.log((xhr.loaded / xhr.total * 100) + '% loaded');
                    // },
                    // // called when loading has errors
                    // (error) => {
                    //     console.log('An error happened', error);
                    // }
                    );
                });
            });

        },

        setTimelines() {

            this.objects.forEach((o, i) => {

                if(typeof o.scene === 'undefined') {
                    return
                }

                // Animation in
                const enter = gsap.timeline({
                    paused: true,
                    onStart: () => {
                        o.scene.visible = true

                        // Reset to default position
                        gsap.set(o.scene.scale, {
                            x: o.scale,
                            y: o.scale,
                            z: o.scale,
                        })
                        gsap.set(o.scene.rotation, {
                            x: o.rotation.base.x,
                            y: o.rotation.base.y,
                            z: o.rotation.base.z,
                        })
                        gsap.set(o.scene.position, {
                            x: this.store.state.mobileLayout ? (i * 15) : o.position.base.x,
                            z: this.store.state.mobileLayout ? 0 : o.position.base.z,
                        })
                    }
                })

                enter
                    .fromTo(o.scene.position,
                        {
                            y: o.position.base.y - 12,
                        },
                        {
                            y: this.store.state.mobileLayout ? 0 : o.position.base.y,
                            duration: 2,
                            ease: 'power2.out',
                            delay: i/10,
                        })

                // Animation out
                const leave = gsap.timeline({
                    paused: true,
                    onComplete: () => {
                       o.scene.visible = false
                    }
                })

                leave
                    .to(o.scene.position, {
                        y: o.position.base.y + 12,
                        duration: 1,
                        ease: 'power2.in'
                    })

                // Hover animation
                const hover = gsap.timeline({
                    paused: true,
                    defaults: {
                        duration: 0.6,
                        ease: 'power2.inOut'
                    },
                })

                hover
                    .fromTo(o.scene.position,
                        {
                            x: o.position.base.x,
                            y: o.position.base.y,
                            z: o.position.base.z
                        }, {
                            x: o.position.hover.x,
                            y: o.position.hover.y,
                            z: o.position.hover.z
                        }, 0)
                    .fromTo(o.scene.rotation,
                        {
                            x: o.rotation.base.x,
                            y: o.rotation.base.y,
                            z: o.rotation.base.z
                        }, {
                            x: o.rotation.hover.x,
                            y: o.rotation.hover.y,
                            z: o.rotation.hover.z,
                        }, 0)

                // productEnter
                const productEnter = gsap.timeline({
                    paused: true,
                    defaults: {
                        duration: 1,
                        ease: 'power2.out'
                    },
                    onStart: () => {
                        o.scene.visible = true
                    }
                })

                productEnter
                    .fromTo(o.scene.position,
                        {
                            x: this.store.state.mobileLayout ? 0 : o.position.base.x,
                            y: this.store.state.mobileLayout ? 0 : o.position.base.y,
                            z: this.store.state.mobileLayout ? 1 : o.position.base.z
                        },
                        {
                            x: 0,
                            y: 0,
                            z: 0
                        }, 0)
                    .fromTo(o.scene.rotation,
                        {
                            x: o.rotation.hover.x,
                            y: o.rotation.hover.y,
                            z: o.rotation.hover.z,
                        },
                        {
                            x: 0,
                            y: 0,
                            z: Math.PI/8
                        }, 0)

                // Header animation
                const header = gsap.timeline({
                    paused: true,
                    defaults: {
                        duration: 1,
                        ease: 'none'
                    },
                    onUpdate: () => {
                        o.scene.visible = header.progress() < 1
                    },
                })

                header
                    .fromTo(o.scene.position,
                        {
                            x: 0,
                            y: 0,
                            z: 0
                        }, {
                            x: this.store.state.mobileLayout ? 0 : 15 * this.ratio.x,
                            y: this.store.state.mobileLayout ? 15 * this.ratio.y : -3 * this.ratio.y,
                            z: 0
                        }, 0)
                    .fromTo(o.scene.rotation,
                        {
                            x: 0,
                            y: 0,
                            z: Math.PI/8
                        }, {
                            x: 0,
                            y: Math.PI,
                            z: -Math.PI/8
                        }, 0)
                    .fromTo(o.scene.scale,
                        {
                            x: o.scale,
                            y: o.scale,
                            z: o.scale
                        }, {
                            x: 1.2 * o.scale,
                            y: 1.2 * o.scale,
                            z: 1.2 * o.scale
                        }, 0)


                // Shop animation
                const shop = gsap.timeline({
                    paused: true,
                    defaults: {
                        duration: 1,
                        ease: 'none'
                    },
                    onUpdate: () => {
                        const progress = shop.progress()
                        o.scene.visible = progress > 0 && progress < 1
                    },
                })

                shop
                    .fromTo(o.scene.position,
                        {
                            x: -4 * this.ratio.x,
                            y: -20 * this.ratio.y,
                            z: 0
                        },
                        {
                            x: 4 * this.ratio.x,
                            y: 20 * this.ratio.y,
                            z: 0
                        }, 0)
                    .fromTo(o.scene.rotation,
                        {
                            x: 0,
                            y: 0,
                            z: Math.PI/8
                        }, {
                            x: 0,
                            y: 2 * Math.PI,
                            z: -Math.PI/8
                        }, 0)

                // Animation product in first load
                const enterAlone = gsap.timeline({
                    paused: true,
                    defaults: {
                        duration: 1,
                        ease: 'power2.out'
                    },
                    onStart: () => {
                        o.scene.visible = true
                        gsap.set(o.scene.scale, {
                            x: o.scale,
                            y: o.scale,
                            z: o.scale
                        }, 0)
                    },
                })

                enterAlone
                    .fromTo(o.scene.position,
                        {
                            x: o.position.base.x,
                            y: o.position.base.y - 12,
                            z: o.position.base.z
                        }, {
                            x: 0,
                            y: 0,
                            z: 0,
                            duration: 2,
                        }, 0)
                    .fromTo(o.scene.rotation,
                        {
                            x: o.rotation.hover.x,
                            y: o.rotation.hover.y,
                            z: o.rotation.hover.z,
                        },
                        {
                            x: 0,
                            y: 0,
                            z: Math.PI/8
                        }, 0)
                    // .fromTo(o.scene.scale,
                    //     {
                    //         x: o.scale,
                    //         y: o.scale,
                    //         z: o.scale
                    //     }, {
                    //         x: 1.2 * o.scale,
                    //         y: 1.2 * o.scale,
                    //         z: 1.2 * o.scale
                    //     }, 0)


                // Floatting animation
                const float = gsap.timeline({
                    repeat: -1,
                    yoyo: true,
                    yoyoEase: 'sine.inOut',
                    delay: i,
                    defaults: {
                        ease: 'sine.inOut',
                        duration: 3,
                    },
                })

                float
                    .fromTo(o.scene.children[0].position,
                        {
                            y: gsap.utils.random(-0.5, -0.2, 0.05) * this.ratio.y,
                        },
                        {
                            y: gsap.utils.random(0.2, 0.5, 0.05) * this.ratio.y,
                        })


                // 360 rotation animation
                const rotate = gsap.timeline({
                    repeat: -1,
                    paused: true,
                    defaults: {
                        duration: 10,
                        ease: 'none'
                    },
                })

                rotate
                    .fromTo(o.scene.children[0].rotation,
                        {
                            y: 0,
                        },
                        {
                            y: 2 * Math.PI,
                        })

                o.timelines = {
                    enter,
                    leave,
                    hover,
                    productEnter,
                    header,
                    enterAlone,
                    shop,
                    float,
                    rotate,
                }
            });
        },

        slide(index=0) {

            this.store.setSwiping(true)

            const lastToFirst = this.currentIndex === this.totalObjectIndexes && index === 0
            const firstToLast = this.currentIndex === 0 && index === this.totalObjectIndexes

            this.objects.forEach((o, i) => {

                // Object to center
                if(i === index) {
                    o.scene.visible = true

                    if(lastToFirst) {
                        // Set first object position to right
                        gsap.set(o.scene.position, {
                            x: 12 * this.ratio.x
                        })
                    } else if(firstToLast) {
                        // Set last object position to left
                        gsap.set(o.scene.position, {
                            x: -12 * this.ratio.x
                        })
                    }

                    // Center object
                    gsap.to(o.scene.position,{
                        duration: 1,
                        ease: 'power.out',
                        x: 0,
                        z: 1 * this.ratio.x,
                        onComplete: () => {
                            this.store.setSwiping(false)
                        }
                    })

                    gsap.to(o.scene.rotation,{
                        duration: 1,
                        ease: 'power.out',
                        x: o.rotation.hover.x,
                        y: o.rotation.hover.y,
                        z: o.rotation.hover.z
                    })

                }
                // Previous object to slide left/right
                else if(i === this.currentIndex) {

                    // Animate current object to base rotation
                    gsap.to(o.scene.rotation,{
                        duration: 1,
                        ease: 'power.out',
                        x: o.rotation.base.x,
                        y: o.rotation.base.y,
                        z: o.rotation.base.z,
                        onComplete: () => {
                            o.scene.visible = false
                        }
                    })

                    if(i < index && !firstToLast || lastToFirst) {
                        // Slide left
                        gsap.to(o.scene.position,{
                            duration: 1,
                            ease: 'power.out',
                            x: -12 * this.ratio.x,
                            z: 0
                        })
                    } else {
                        // Slide right
                        gsap.to(o.scene.position,{
                            duration: 1,
                            ease: 'power.out',
                            x: 12 * this.ratio.x,
                            z: 0
                        })
                    }
                } else if(i > index) {
                    // Set next object positions to right
                    gsap.set(o.scene.position, {
                        x: 12 * this.ratio.x
                    })
                } else if(i < index) {
                    // Set previous object positions to left
                    gsap.set(o.scene.position, {
                        x: -12 * this.ratio.x
                    })
                }
            })

            this.currentIndex = index

        },

        mousemove(e) {

            const x = ( e.clientX / this.sizes.width ) * 2 - 1;
            const y = - ( e.clientY / this.sizes.height ) * 2 + 1;

            let vector = new THREE.Vector3(x, y, 2);
            vector.unproject( this.camera );
            let dir = vector.sub( this.camera.position ).normalize();
            let distance = - this.camera.position.z / dir.z;
            this.worldMousePosition = this.camera.position.clone().add( dir.multiplyScalar( distance ) );
        },

        animateLeave(id) {

            return new Promise(resolve => {

                if(this.currentObject) {
                    this.currentObject.timelines.leave.restart()
                    this.currentObject.timelines.leave.then(() => {
                        resolve()
                    })
                } else {
                    this.objects.forEach(o => {

                        if(o.id !== id) {
                            o.timelines.leave.restart()
                            o.timelines.leave.then(() => {
                                resolve()
                            })
                        }
                    })
                }
            })
        },

        animateEnter(id, first=false) {

            // Animate laser
            const laserDelay = first ? 0 : 1
            this.$refs.laser.show(laserDelay)
            this.$refs.laser.center()

            // Stop product rotation with ease
            if(this.currentHover !== null) {
                this.currentHover.timelines.rotate.pause()
                gsap.to(this.currentHover.timelines.rotate, {
                    progress: 0,
                    duration: 1,
                    ease: 'power2.out'
                })
            }

            return new Promise(resolve => {

                // Show object with id
                if(id) {
                    const o = this.getObjectById(id)

                    if(typeof o === 'undefined') {
                        resolve()
                    }

                    if(first || this.currentObject) {
                        o.timelines.enterAlone.restart()
                        o.timelines.enterAlone.then(() => {
                            resolve()
                        })
                    } else {
                        o.timelines.productEnter.restart()
                        o.timelines.productEnter.then(() => {
                            resolve()
                        })
                    }

                    this.currentObjectId = id
                }
                // Show all objects
                else {
                    this.objects.forEach((o, i) => {

                        if(i === 0) {
                            o.timelines.enter.restart().then(() => {
                                resolve()
                            })
                        } else {
                            o.timelines.enter.restart()
                        }
                    })

                    this.currentObjectId = false

                }
            })
        },

        animateHover(id) {
            const o = this.getObjectById(id)

            // Zoom out previous object if exists
            if(this.currentHover !== null) {
                this.currentHover.timelines.hover.reverse()
                this.currentHover.timelines.rotate.pause()

                gsap.to(this.currentHover.scene.rotation, {
                    y: 0,
                    duration: .6,
                    ease: 'power2.out'
                })
            }

            // Zoom in object
            if(o) {
                o.timelines.hover.play()
                o.timelines.rotate.play()

                this.currentHover = o;

                gsap.to(this.redLight,{
                    duration: 2,
                    intensity: 1
                });

                gsap.to(this.blueLight,{
                    duration: 2,
                    intensity: 1
                });

                gsap.to(this.beamsWrapper.position,{
                    duration: 1,
                    x: (o.scene.position.x + 1) * this.ratio.x,
                    y: 0,
                })

            }
        },

        tick() {
            this.raf = requestAnimationFrame(() => this.tick());

            if(this.store.state.sceneIsLoaded) {
                this.pointLight.position.set(-this.worldMousePosition.x, -this.worldMousePosition.y, 10);
                this.backLight.position.set(-this.worldMousePosition.x, -this.worldMousePosition.y, 0);
            }

            // this.controls.update();
            this.renderer.render(this.scene, this.camera);
        },

        resize() {
            // Update sizes
            this.sizes.width = window.innerWidth
            this.sizes.height = window.innerHeight

            // Update camera
            this.camera.aspect = this.sizes.width / this.sizes.height
            this.camera.updateProjectionMatrix()

            // Update renderer
            this.renderer.setSize(this.sizes.width, this.sizes.height)
            this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

            // Calculate object positions
            this.setObjectsPositions()

            this.currentObjectId = this.store.currentProductId

            // Timeline state
            if(this.store.state.sceneIsLoaded) {
                this.setTimelines()

                if(this.currentObject) {
                    if(this.store.state.productShopTimelineProgress > 0) {
                        this.currentObject.timelines.shop.progress(this.store.state.productShopTimelineProgress)
                    } else {
                        this.currentObject.timelines.header.progress(this.store.state.productHeaderTimelineProgress)
                    }
                } else {
                    this.animateEnter(this.currentObjectId)
                }
            }
        },

        getObjectById(id) {
            return this.objects.find(o => (o.id === id && o.scene !== undefined))
        },

        /**
         * GUI
         * Uncomment for debug purpose
        **/

        /*
        initGui() {
            this.gui = new dat.GUI();

            const textureLoader = new THREE.TextureLoader();

            const sceneControlsFunction = function () {
                // Shiny material
                this.m1Color = '#0F0F0F';
                // this.m1EnvMapTexture = require('/img/texture-1.jpg');
                this.m1Specular = '#9C9C9C';
                this.m1Shininess = 20;
                this.m1Reflectivity = 1;

                // Glass material
                this.m2Color = '#ffffff';
                this.m2Opacity = 0.5;
                this.m2Shininess = 100;

                this.piece1Material = 'shinyMaterial';
                this.piece2Material = 'shinyMaterial';
                this.piece3Material = 'shinyMaterial';
                this.piece4Material = 'shinyMaterial';
                this.piece5Material = 'shinyMaterial';

                this.clearColor = "#000000";
                this.lightIntensity = 1.6;
                this.lightColor = '#969696';

                this.rotationX = 0;
                this.rotationY = 0;
                this.rotationZ = 0;
            };

            this.sceneControls = new sceneControlsFunction();

            this.gui.add(this.sceneControls, 'lightIntensity', 0, 2).onChange(() => this.updateGui());
            this.gui.addColor(this.sceneControls, 'lightColor').onChange(() => this.updateGui());
            this.gui.addColor(this.sceneControls, 'clearColor').onChange(() => this.updateGui());

            this.objectRotationFolder = this.gui.addFolder('Object rotation');
            this.objectRotationFolder.add(this.sceneControls, 'rotationX', -Math.PI, Math.PI).onChange(() => this.updateGui());
            this.objectRotationFolder.add(this.sceneControls, 'rotationY', -Math.PI, Math.PI).onChange(() => this.updateGui());
            this.objectRotationFolder.add(this.sceneControls, 'rotationZ', -Math.PI, Math.PI).onChange(() => this.updateGui());

            this.m1Folder = this.gui.addFolder('Shiny Material - m1');
            this.m1Folder.addColor(this.sceneControls, `m1Color`).onChange(() => this.updateGui());
            this.m1Folder.addImage(this.sceneControls, `m1EnvMapTexture`).onChange((image) => {
                textureLoader.load(image.src, (texture) => {
                    texture.encoding = THREE.sRGBEncoding;
                    texture.mapping = THREE.EquirectangularReflectionMapping;

                    this.materials[0].material.envMap = texture;
                    this.materials[0].material.needsUpdate = true;
                });
            });

            this.m1Folder.addColor(this.sceneControls, `m1Specular`).onChange(() => this.updateGui());
            this.m1Folder.add(this.sceneControls, `m1Shininess`, 0, 100).onChange(() => this.updateGui());
            this.m1Folder.add(this.sceneControls, `m1Reflectivity`, 0, 1).onChange(() => this.updateGui());

            this.m2Folder = this.gui.addFolder('Glass Material - m2');
            this.m2Folder.addColor(this.sceneControls, `m2Color`).onChange(() => this.updateGui());
            this.m2Folder.add(this.sceneControls, `m2Opacity`, 0, 1).onChange(() => this.updateGui());
            this.m2Folder.add(this.sceneControls, `m2Shininess`, 0, 100).onChange(() => this.updateGui());

            this.objects.forEach(item => {
                let objectFolder = this.gui.addFolder(item.scene.children[0].name);

                item.scene.children[0].children.forEach((children, index) => {
                    let childrenFolder = objectFolder.addFolder(children.name);

                    if(index === 0) {
                        childrenFolder.add(this.sceneControls, `piece1Material`, ['shinyMaterial', 'glassMaterial']).onChange((value) => {
                            this.materials.forEach((materialData) => {
                                if(materialData.name == value) {
                                    children.traverse(node => {
                                        node.material = materialData.material;
                                    });
                                }
                            });

                        });
                    } else if (index === 1) {
                        childrenFolder.add(this.sceneControls, `piece2Material`, ['shinyMaterial', 'glassMaterial']).onChange((value) => {
                            this.materials.forEach((materialData) => {
                                if(materialData.name == value) {
                                    children.traverse(node => {
                                        node.material = materialData.material;
                                    });
                                }
                            });
                        });
                    } else if (index === 2) {
                        childrenFolder.add(this.sceneControls, `piece3Material`, ['shinyMaterial', 'glassMaterial']).onChange((value) => {
                            this.materials.forEach((materialData) => {
                                if(materialData.name == value) {
                                    children.traverse(node => {
                                        node.material = materialData.material;
                                    });
                                }
                            });
                        });
                    } else if (index === 3) {
                        childrenFolder.add(this.sceneControls, `piece4Material`, ['shinyMaterial', 'glassMaterial']).onChange((value) => {
                            this.materials.forEach((materialData) => {
                                if(materialData.name == value) {
                                    children.traverse(node => {
                                        node.material = materialData.material;
                                    });
                                }
                            });
                        });
                    } else if (index === 4) {
                        childrenFolder.add(this.sceneControls, `piece5Material`, ['shinyMaterial', 'glassMaterial']).onChange((value) => {
                            this.materials.forEach((materialData) => {
                                if(materialData.name == value) {
                                    children.traverse(node => {
                                        node.material = materialData.material;
                                    });
                                }
                            });
                        });
                    }

                })
            });

            this.updateGui();

        },

        updateGui() {
            this.materials[0].material.color = new THREE.Color(this.sceneControls.m1Color);
            this.materials[0].material.specular = new THREE.Color(this.sceneControls.m1Specular);
            this.materials[0].material.shininess = this.sceneControls.m1Shininess;
            this.materials[0].material.reflectivity = this.sceneControls.m1Reflectivity;

            this.materials[1].material.color = new THREE.Color(this.sceneControls.m2Color);
            this.materials[1].material.opacity = this.sceneControls.m2Opacity;
            this.materials[1].material.shininess = this.sceneControls.m2Shininess;


            this.renderer.setClearColor(new THREE.Color(this.sceneControls.clearColor));

            // this.pointLight.intensity = this.sceneControls.lightIntensity;
            // this.backLight.intensity = this.sceneControls.lightIntensity;

            // this.pointLight.color = new THREE.Color(this.sceneControls.lightColor);
            // this.backLight.color = new THREE.Color(this.sceneControls.lightColor);

            if(this.store.state.sceneIsLoaded) {
                this.objects.forEach((object) => {
                    object.scene.rotation.set(this.sceneControls.rotationX, this.sceneControls.rotationY, this.sceneControls.rotationZ)
                });
            }
        },

        *
        * END GUI
        */
    },

    computed: {
        totalObjects() {
            return this.objects.length
        },
        totalObjectIndexes() {
            return this.totalObjects - 1
        },
        currentObject() {
            return this.currentObjectId ? this.getObjectById(this.currentObjectId) : false
        },
        ratio() {

            const coef = this.sizes.width < 700 ? 700 : this.sizes.width > 1800 ? 1400 : 1000
            let x = this.sizes.width/coef
            let y = this.sizes.height/coef

            const hyp = Math.hypot(this.sizes.width, this.sizes.height)
            const xy = hyp/coef

            return {
                x,
                y,
                xy
            }
        },
        ariaLabel() {
            let label = 'Featured products: '
            label += this.store.state.products.map(p => p.name).join(', ')

            return label
        }
    },

    watch: {
        'store.state.productHover': function() {
            if(!this.store.hasProductHover) {
                return
            }

            if(this.store.state.mobileLayout) {
                this.slide(this.store.state.productHoverIndex)
            } else {
                this.animateHover(this.store.state.productHover)
            }
        },
        'store.state.productHeaderTimelineProgress': function(progress) {
            if(this.store.state.isReady && this.currentObject && this.store.hasCurrentProduct) {
                this.currentObject.timelines.header.progress(progress)
            }
        },
        'store.state.productShopTimelineProgress': function(progress) {
            if(this.store.state.isReady && this.currentObject && this.store.hasCurrentProduct) {
                this.currentObject.timelines.shop.progress(progress)
            }
        },
    },

    // Uncomment if scene is unmounted

    // beforeUnmount() {
    //     cancelAnimationFrame(this.raf);

    //     window.removeEventListener('resizeEnd', this.onResize);
    //     window.removeEventListener('mousemove', this.onMouseMove, false );
    // }
}

</script>

<style lang="scss">

.c-scene {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.c-scene_canvas {
}

</style>
