<template>
    <div class="o-laser">
        <div
            ref="inner"
            class="o-laser_inner"
        >
            <span
                ref="laserRed"
                class="o-laser_ray -red"
            ></span>
            <span
                ref="laserBlue"
                class="o-laser_ray -blue"
            ></span>
        </div>
    </div>
</template>

<script>

import { gsap } from '@/gsap'

export default {
    name: 'Laser',
    inject: ['store'],
    data: () => ({
        currentPosition: 0,
        isHidden: false,
    }),
    mounted() {
        this.$inner = this.$refs.inner
        this.$red = this.$refs.laserRed
        this.$blue = this.$refs.laserBlue

        this.timelineRed = gsap.timeline({
            paused: true,
            repeat: -1,
            yoyo: true,
            yoyoEase: 'sine.inOut',
            defaults: {
                duration: 6,
                ease: 'none'
            }
        })

        this.timelineRed.to(this.$red, {
            rotation: 40,
        }, 0)

        this.timelineBlue = gsap.timeline({
            paused: true,
            repeat: -1,
            yoyo: true,
            yoyoEase: 'sine.inOut',
            defaults: {
                duration: 10,
                ease: 'none'
            }
        })

        this.timelineBlue.to(this.$blue, {
            rotation: -110,
        }, 0)


        this.timelineRotation = gsap.timeline({
            paused: true,
            repeat: -1
        })

        this.timelineRotation.to(this.$inner, {
            rotation: 360,
            duration: 70,
            ease: 'none'
        })

        this.timelineHover = gsap.timeline({
            paused: true,
        })

        this.timelineHover
            .fromTo(this.timelineRed,
                {
                    timeScale: 3,
                },
                {
                    ease: 'power2.inOut',
                    duration: .8,
                    timeScale: 1,
                    immediateRender: false
                }, 0)
            .fromTo(this.timelineBlue,
                {
                    timeScale: 5,
                },
                {
                    ease: 'power2.inOut',
                    duration: .8,
                    timeScale: 1,
                    immediateRender: false
                }, 0)

    },
    methods: {
        show(delay=0) {
            gsap.to(this.$el, {
                opacity: 1,
                scale: 1,
                duration: .4,
                ease: 'sine.out',
                delay,
                onStart: () => {
                    this.timelineRed.play()
                    this.timelineBlue.play()
                    this.timelineRotation.play()
                    this.isHidden = false
                }
            })
        },
        hide() {
            gsap.to(this.$el, {
                opacity: 0,
                scale: 0,
                duration: .4,
                ease: 'sine.out',
                onStart: () => {
                    this.isHidden = true
                },
                onComplete: () => {

                    if(this.isHidden) {
                        this.timelineRed.pause()
                        this.timelineBlue.pause()
                        this.timelineRotation.pause()
                    }
                }
            })
        },
        center() {
            gsap.to(this.$inner, {
                xPercent: 0,
                duration: .8,
                ease: 'sine.out',
            })
        },
        updateHover(index) {

            this.timelineHover.play(0)

            if(!this.store.isMobile) {
                const position = 25 * (index - this.store.totalProductIndexes/2)
                gsap.to(this.$inner, {
                    xPercent: position,
                    duration: .8,
                    ease: 'power2.out',
                })
            }
        }
    },

    watch: {
        'store.state.productHoverIndex': function(index) {
            if(this.store.hasProductHover) {
                this.updateHover(index);
            }
        },
        'store.state.productHeaderTimelineProgress': function(progress) {

            if(!this.store.state.isReady) {
                return
            }

            if(progress >= 0.001 && !this.isHidden) {
                this.hide()

            } else if(progress < 0.001 && this.isHidden) {
                this.show()
            }
        }
    },
}

</script>

<style lang="scss">

.o-laser {
    --laser-width   : 300vmax;
    --laser-height  : 1px;

    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
}

.o-laser_inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    will-change: transform;
}

.o-laser_ray {
    position: absolute;
    top: calc(50% - var(--laser-height)/2);
    left: calc(50% - var(--laser-width)/2);
    display: block;
    width: var(--laser-width);
    height: var(--laser-height);
    transform-style: preserve-3d;
    will-change: transform;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: inherit;
        filter: blur(2px);
        transform: scale(1, 2);
        transition: transform 1s $easing;

        html.-firstload & {
            transform: scale(1, 0);
        }
    }

    &.-blue {
        background-color: $color-primary;
    }

    &.-red {
        background-color: $color-secondary;
    }
}

</style>
