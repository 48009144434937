import { reactive, computed, readonly } from 'vue'
import { general, products } from '@/data.js'

// Constants
const IS_MOBILE = (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

// Storefront API
const STOREFRONT_ACCESS_TOKEN = 'bef2c7dea78a3abc10bb93926495cba5'
const GRAPHQL_URL = 'https://dr-dabber.myshopify.com/api/2020-10/graphql.json'


// State
const state = reactive({
    general,
    products,

    currentProduct: null,
    isOfAge: null,
    isReady: false,
    isSwiping: false,
    isFirstLoaded: false,
    mobileLayout: null,
    productHeaderTimelineProgress: 0,
    productHover: null,
    productHoverIndex: null,
    productShopTimelineProgress: 0,
    productVideoIsLoaded: false,
    sceneIsLoaded: false,
    shopifyProductsLoaded: false,
    soundOn: true,
})

// Getters
const getProductById      = (id=null)    => state.products.find(p => p.id === id)
const getProductIndexById = (id=null)    => state.products.findIndex(p => p.id === id)
const getProductIdByIndex = (index=null) => state.products[index].id

// Dispatch
const setCurrentProduct = (id=null) => {
    const product = getProductById(id)
    state.currentProduct = typeof product !== 'undefined' ? product : null;
}

const setProductHover = (id=null) => {
    state.productHover = id;
    state.productHoverIndex = getProductIndexById(id)
}

const setProductHeaderTimelineProgress = (progress=0) => {
    state.productHeaderTimelineProgress = progress;
}

const setProductShopTimelineProgress = (progress=0) => {
    state.productShopTimelineProgress = progress;
}

const sceneLoaded = () => {
    state.sceneIsLoaded = true;
}

const setProductVideoLoaded = (loaded=true) => {
    state.productVideoIsLoaded = loaded;
}

const setReady = (ready=true) => {
    state.isReady = ready;

    if(ready && state.currentProduct !== null) {
        setProductHover()
    }
}

const setSwiping = (swiping=false) => {
    state.isSwiping = swiping;
}

const firstloaded = () => {
    state.isFirstLoaded = true;
}

const setMobileLayout = isMobileLayout => {
    state.mobileLayout = isMobileLayout
}

const setIsOfAge = ofAge => {
    state.isOfAge = ofAge
}

const toggleSound = () => {
    state.soundOn = !state.soundOn
}


const loadGeneral = () => {

    const query = `
        query {
            shop {
                primaryDomain {
                    url
                }
            }
        }
    `

    fetch(GRAPHQL_URL, {
            method: 'POST',
            crossDomain: true,
            headers: {
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
                'Content-Type': 'application/graphql',
            },
            body: query
        })
        .then(r => r.json())
        .then(r => {

            state.general.shopUrl = r.data.shop.primaryDomain.url
        })
}

const loadShopifyProducts = () => {

    const promises = []
    state.products.forEach((product, i) => {

        if(!product.collection.shopifyProductIds) {
            return
        }

        const gids = product.collection.shopifyProductIds.map(id => btoa(`gid://shopify/Product/${id}`));
        const query = `
        {
            nodes (ids: ${JSON.stringify(gids)}) {
                ...on Product {
                    id
                    title
                    onlineStoreUrl
                    images(first: 1) {
                        edges {
                            node {
                                originalSrc
                            }
                        }
                    }
                    priceRange {
                        minVariantPrice {
                            amount
                        }
                        maxVariantPrice {
                            amount
                            currencyCode
                        }
                    }
                }
            }
            shop {
                primaryDomain {
                    url
                }
            }
        }
        `

        promises.push(fetch(GRAPHQL_URL, {
                method: 'POST',
                crossDomain: true,
                headers: {
                    'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
                    'Content-Type': 'application/graphql',
                },
                body: query
            })
            .then(r => r.json())
            .then(r => {
                const baseUrl = r.data.shop.primaryDomain.url

                // Parse
                let image, price, discount, currency
                const data = []
                r.data.nodes.forEach(item => {

                    if(item === null) {
                        return
                    }

                    // Image
                    image = item.images.edges
                    image = image.length > 0 ? image[0].node.originalSrc : false

                    // Prices
                    price = item.priceRange.maxVariantPrice.amount
                    discount = item.priceRange.minVariantPrice.amount
                    discount = price > discount ? discount : false

                    // Currency
                    currency = item.priceRange.maxVariantPrice.currencyCode

                    data.push({
                        title: item.title,
                        url: item.onlineStoreUrl,
                        image,
                        price,
                        discount,
                        currency
                    })
                })

                state.products[i].collection.items = data
                state.products[i].collection.url = `${baseUrl}/collections/${product.collection.shopifyHandle}`
            }))
    })

    Promise.all(promises).then(() => {
        state.shopifyProductsLoaded = true
    })
}


// Computed
const currentProductId      = computed(() => state.currentProduct !== null ? state.currentProduct.id : null)
const hasCurrentProduct     = computed(() => state.currentProduct !== null)
const hasProductHover       = computed(() => state.productHover !== null)
const hasSoundOn            = computed(() => state.soundOn === true)
const productLoaded         = computed(() => state.productVideoIsLoaded && state.shopifyProductsLoaded)
const shopUrl               = computed(() => state.currentProduct !== null ? state.currentProduct.url : state.general.shopUrl)
const totalProducts         = computed(() => state.products.length)
const totalProductIndexes   = computed(() => state.products.length - 1)


// Export
export const store = readonly({

    // Global Vars
    isMobile: IS_MOBILE,
    isProd: process.env.NODE_ENV === 'production',

    // State
    state,

    // Getters
    getProductById,
    getProductIdByIndex,
    getProductIndexById,

    // Dispatch
    firstloaded,
    loadShopifyProducts,
    loadGeneral,
    sceneLoaded,
    setCurrentProduct,
    setIsOfAge,
    setMobileLayout,
    setProductHeaderTimelineProgress,
    setProductHover,
    setProductShopTimelineProgress,
    setProductVideoLoaded,
    setReady,
    setSwiping,
    toggleSound,

    // Computed
    currentProductId,
    hasCurrentProduct,
    hasProductHover,
    hasSoundOn,
    productLoaded,
    shopUrl,
    totalProducts,
    totalProductIndexes,
});
