<template>

    <grid-debugger v-if="!store.isProd" />

    <loader v-if="firstload" />

    <app-header />

    <scene ref="scene" />

    <div
        ref="scroll"
        class="o-scroll"
    >
        <main ref="scrollInner">
            <router-view v-slot="{ Component }">
                <transition
                    mode="out-in"
                    :css="false"
                    @before-leave="transitionBeforeLeave"
                    @leave="transitionLeave"
                    @enter="transitionEnter"
                    @after-enter="transitionAfterEnter"
                >
                    <component :is="Component" />
                </transition>
            </router-view>
        </main>
    </div>
</template>

<script>

import '@/assets/styles/main.scss'

import AppHeader                from '@/templates/components/AppHeader'
import Scene                    from '@/templates/components/Scene'
import GridDebugger             from '@/templates/objects/GridDebugger'
import Loader                   from '@/templates/objects/Loader'

import { store }                from '@/store'
import { assets, meta }         from '@/data'
import { loadAudio }            from '@/utils/audio'

import { gsap, ScrollTrigger }  from '@/gsap'

const $html                 = document.documentElement
const $metaDescription      = document.querySelector('meta[name="description"]')
const $metaOgTitle          = document.querySelector('meta[property="og:title"]')
const $metaOgDescription    = document.querySelector('meta[property="og:description"]')
const $metaOgUrl            = document.querySelector('meta[property="og:url"]')
const BASE_URL              = process.env.VUE_APP_BASE_URL || ''

export default {
    name: 'App',
    provide: {
        store
    },
    components: {
        AppHeader,
        Scene,
        GridDebugger,
        Loader,
    },
    data: () => ({
        store,
        firstload: true,
        productIsAnimated: true,
        routes: {}
    }),
    created() {
        // Load ambiant sound
        this.soundAmbiant = loadAudio(assets.audio.ambiance, 0)
        this.soundAmbiant.loop = true

        // Define mobile layout
        this.resize(true)

        // window events
        window.addEventListener('resizeEnd', () => this.resize())

        this.store.loadGeneral()
        this.store.loadShopifyProducts()
    },
    mounted() {

        if(this.hasSmoothScroll) {
            this.scroller = this.smoothScroll()
        }
    },
    methods: {
        transitionBeforeLeave() {
            $html.classList.add('is-loading')
            $html.classList.remove('is-ready')
            this.store.setReady(false)
            this.store.setProductVideoLoaded(false)
            this.productIsAnimated = false
        },
        transitionLeave(el, done) {

            if(this.routes.previous !== 'play') {
                // Product animation leave
                this.$refs.scene.animateLeave(this.store.currentProductId).then(() => {
                    done()
                })
            } else {
                this.$nextTick(done)
            }
        },
        transitionEnter(el, done) {

            // Scroll to top
            window.scrollTo(0, 0)

            if(!this.firstload && !this.playPage) {
                this.$refs.scene.animateEnter(this.store.currentProductId).then(() => {
                    this.productIsAnimated = true
                })
            }

            if(this.playPage && this.store.hasSoundOn) {
                this.toggleAmbiantSound(false)
            } else if(!this.firstload && !this.playPage && this.store.hasSoundOn) {
                this.toggleAmbiantSound(true)
            }

            this.waitForLoad().then(() => {

                if(this.firstload) {

                    $html.classList.remove('-firstload')

                    // Set firstload to false
                    this.firstload = false
                    if(!this.playPage) {
                        this.toggleAmbiantSound(true)
                        this.$refs.scene.animateEnter(this.store.currentProductId, true).then(() => {
                            done()
                        })
                    } else {
                        done()
                    }
                } else {
                    done()
                }
            })
        },
        transitionAfterEnter() {

            // Scroll recalculation and scroll to top
            window.scrollTo(0, 0)
            ScrollTrigger.refresh()

            this.store.setReady(true)
            $html.classList.add('is-ready')
            $html.classList.remove('is-loading')
        },
        waitForLoad() {
            return new Promise(resolve => {

                const self = this
                function wait(r) {
                    if (self.isLoaded) {
                        r();
                    } else {
                        setTimeout(wait.bind(this, r), 100);
                    }
                }

                new Promise(wait).then(() => {
                    resolve()
                });
            })
        },
        setMeta() {
            let title = meta.title
            let description = meta.description

            if(this.store.hasCurrentProduct) {
                const product = this.store.state.currentProduct

                title = meta.title + ' — ' + product.name
                description = product.intro
            }

            document.title = title
            $metaOgTitle.setAttribute('content', title)
            $metaDescription.setAttribute('content', description)
            $metaOgDescription.setAttribute('content', description)
            $metaOgUrl.setAttribute('content', BASE_URL + window.location.pathname)
        },


        toggleAmbiantSound(play) {

            gsap.to(this.soundAmbiant, {
                volume: play ? .05 : 0,
                duration: .4,
                onStart: () => {
                    if(play) {
                        this.soundAmbiant.play()
                    }
                },
                onComplete: () => {
                    if(!store.hasSoundOn) {
                        this.soundAmbiant.pause()
                    }
                }
            })
        },

        // this is the helper function that sets it all up. Pass in the content <div> and then the wrapping viewport <div> (can be the elements or selector text). It also sets the default "scroller" to the content so you don't have to do that on all your ScrollTriggers.
        smoothScroll() {
            const smoothness = 1;

            gsap.set(this.$refs.scroll, {overflow: 'hidden', position: 'fixed', height: '100%', width: '100%', top: 0, left: 0, right: 0, bottom: 0});
            gsap.set(this.$refs.scrollInner, {overflow: 'visible', width: '100%'});

            const getProp = gsap.getProperty(this.$refs.scrollInner)
            const setProp = gsap.quickSetter(this.$refs.scrollInner, 'y', 'px')
            const setScroll = ScrollTrigger.getScrollFunc(window)
            const removeScroll = () => this.$refs.scrollInner.style.overflow = 'visible'
            const killScrub = trigger => {
                let scrub = trigger.getTween()
                scrub && scrub.kill();
                trigger.animation.progress(trigger.progress);
            }

            let isProxyScrolling;

            this.refreshSmoothScroll();

            ScrollTrigger.addEventListener('refreshInit', this.refreshSmoothScroll.bind(this));
            ScrollTrigger.addEventListener('refresh', () => {
                removeScroll();
                requestAnimationFrame(removeScroll);
            })
            ScrollTrigger.defaults({scroller: this.$refs.scrollInner});

            ScrollTrigger.scrollerProxy(this.$refs.scrollInner, {
                scrollTop(value) {
                    if (arguments.length) {
                        isProxyScrolling = true; // otherwise, if snapping was applied (or anything that attempted to SET the scroll proxy's scroll position), we'd set the scroll here which would then (on the next tick) update the content tween/ScrollTrigger which would try to smoothly animate to that new value, thus the scrub tween would impede the progress. So we use this flag to respond accordingly in the ScrollTrigger's onUpdate and effectively force the scrub to its end immediately.
                        setProp(-value);
                        setScroll(value);
                        return;
                    }
                    return -getProp('y');
                },
                getBoundingClientRect() {
                    return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
                }
            });

            return ScrollTrigger.create({
                animation: gsap.fromTo(this.$refs.scrollInner, {y:0}, {
                    y: () => document.documentElement.clientHeight - this.scrollHeight,
                    ease: 'none',
                    onUpdate: ScrollTrigger.update
                }),
                scroller: window,
                invalidateOnRefresh: true,
                start: 0,
                end: () => this.scrollHeight - document.documentElement.clientHeight,
                scrub: smoothness,
                onUpdate: self => {
                    if (isProxyScrolling) {
                        killScrub(self);
                        isProxyScrolling = false;
                    }
                },
                onRefresh: killScrub // when the screen resizes, we just want the animation to immediately go to the appropriate spot rather than animating there, so basically kill the scrub.
            });
        },
        refreshSmoothScroll() {
            this.scrollHeight = this.$refs.scrollInner.clientHeight;
            this.$refs.scrollInner.style.overflow = 'visible'
            document.body.style.height = this.scrollHeight + 'px';
        },

        resize(first=false) {

            if(this.store.isMobile && first) {
                $html.style.setProperty('--vh-initial', `${$html.offsetHeight/100}px`)
            }

            // Define mobile layout
            const mobileLayout = this.store.isMobile || window.innerWidth < 700
            this.store.setMobileLayout(mobileLayout)

            // Layout type
            if(mobileLayout) {
                $html.classList.add('-layout-mobile')
                $html.classList.remove('-layout-desktop')
            } else {
                $html.classList.add('-layout-desktop')
                $html.classList.remove('-layout-mobile')
            }

            this.$nextTick(() => {
                ScrollTrigger.refresh()
            })
        },
    },
    computed: {
        hasSmoothScroll() {
            return !this.store.isMobile
        },
        isLoaded() {
            if(this.store.hasCurrentProduct) {
                return this.store.state.sceneIsLoaded && this.store.productLoaded && this.productIsAnimated && this.store.state.isFirstLoaded
            } else if(this.playPage) {
                return this.store.state.isFirstLoaded
            } else {
                return this.store.state.sceneIsLoaded && this.productIsAnimated && this.store.state.isFirstLoaded
            }
        },
        playPage() {
            return this.routes.current === 'play'
        }
    },
    watch: {
        '$route' (to, from) {
            this.routes.current = to.name.toLowerCase()

            if(!this.firstload && !this.store.state.isOfAge && !this.playPage) {
                this.$router.push({ path: '/play' })
            }

            // Set active product in store
            this.store.setCurrentProduct(to.params.id)

            if(from.name) {
                this.routes.previous = from.name.toLowerCase()
                $html.classList.remove(`page-${this.routes.previous}`)
            }


            $html.classList.add(`page-${this.routes.current}`)

            this.setMeta()
        },
        'store.hasSoundOn' (enabled) {
            this.toggleAmbiantSound(enabled)
        }
    }
}
</script>

<style lang="scss">

.o-scroll {
    min-height: vh(100);

    html.is-loading & {
        opacity: 0;
    }
}

</style>
