import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ "../templates/views/Home.vue"
        )
    },
    {
        path: '/product/:id',
        name: 'Product',
        component: () => import(/* webpackChunkName: "product" */ "../templates/views/Product.vue"
        )
    },
    {
        path: '/play',
        name: 'Play',
        component: () => import(/* webpackChunkName: "play" */ "../templates/views/Play.vue"
        )
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/play'
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    base: '/',
    linkActiveClass: 'is-current',
    linkExactActiveClass: '',
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

export default router
