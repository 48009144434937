<template>
    <form
        v-if="inDOM"
        class="o-age-form"
        :class="{ 'is-visible' : isVisible }"
    >
        <anim-text
            text="Are you over 21?"
            type="chars"
            class="o-age-form_title | c-heading -h4"
            :visible="isVisible"
        />
        <div class="o-age-form_field -no">
            <input
                id="age-form-1"
                type="radio"
                :value="false"
                v-model="value"
            >
            <app-button
                tag="label"
                for="age-form-1"
                :sound="false"
            >
                <span class="c-heading -h4">No</span>
            </app-button>
        </div>

        <div class="o-age-form_field -yes">
            <input
                id="age-form-2"
                type="radio"
                :value="true"
                v-model="value"
            >
            <app-button
                tag="label"
                for="age-form-2"
                :sound="false"
                display="primary"
                class="c-heading -h4"
            >
                <span class="c-heading -h4">Yes</span>
            </app-button>
        </div>
    </form>
</template>

<script>

import AnimText     from '@/templates/objects/AnimText'
import AppButton    from '@/templates/components/AppButton'

export default {
    name: 'AgeForm',
    inject: ['store'],
    components: {
        AnimText,
        AppButton,
    },
    data: () => ({
        value: null,
        isVisible: false,
        inDOM: true,
    }),
    methods: {
        show() {
            this.isVisible = true
        },
        hide() {
            this.isVisible = false
            setTimeout(() => {
                this.inDOM = false
            }, 600);
        }
    },
    watch: {
        value(val) {
            this.$emit('update', val)
        },
    },
}

</script>

<style lang="scss">

.o-age-form {
    width: 100%;
    display: flex;
    align-items: flex-end;
    color: $color-primary;
    pointer-events: initial;

    &.is-visible {

        .o-age-form_field {
            transform: translate(0);
            transition: transform $speed-slow $easing;
        }
    }

    @media (max-width: $to-small) {
        flex-wrap: wrap;
        justify-content: center;
    }

    @media (min-width: $from-small) {
    }
}

.o-age-form_title {
    padding: var(--grid-gutter);
    line-height: 1.2 !important;

    @media (max-width: $to-small) {
        width: 100%;
        margin-bottom: .2em;
        text-align: center;
    }
}

.o-age-form_field {
    transform: translate(0, calc(100% + 1px));
    will-change: transform;
    transition: transform $speed-slow $easing;

    input {
        display: none;
    }

    .c-button {
        width: 100%;
        height: 100%;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
        border-radius: 0;
    }

    .c-button_inner {
        justify-content: center;
    }

    .c-button_label {
        text-transform: none;
    }

    @media (max-width: $to-small) {
        width: 50vw;
    }

    @media (min-width: $from-small) and (max-width: $to-medium) {
        width: calc(1/5 * 100vw);
    }

    @media (min-width: $from-small) {

        &.-no {
            margin-left: auto;
        }
    }

    @media (min-width: $from-medium) {
        width: calc(1/8 * 100vw);
    }
}

</style>
