<template>
    <component
        :is="tag"
        :class="className"
        :style="styles"
    >
        <i
            v-if="separator === true"
            class="o-text_separator"
        ></i>
        <icon
            v-else-if="separatorIcon"
            name="half-circles"
            class="o-text_separator"
        />
        {{ text }}
    </component>
</template>

<script>

import Icon from '@/templates/objects/Icon'

import { ScrollTrigger, SplitText } from '@/gsap'

const CLASSNAME = 'o-at'

export default {
    name: 'AnimText',
    components: {
        Icon
    },
    props: {
        tag: {
            type: String,
            default: 'p'
        },
        text: {
            type: String,
            required: true
        },
        separator: {
            type: Boolean,
            default: false
        },
        separatorIcon: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'lines'
        },
        visible: {
            type: Boolean,
            default: false
        },
        reveal: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        styles: false,
        isVisible: false,
    }),
    created() {
        this.isVisible = this.visible
    },
    mounted() {

        this.initSplits()

        if(this.reveal) {
            this.scrollTrigger = ScrollTrigger.create({
                trigger: this.$el,
                start: 'top bottom-=20x',
                end: 'top top',
                onEnter: () => {
                    this.show()
                },
                onEnterBack: () => {
                    this.show()
                },
                onLeaveBack: () => {
                    this.hide()
                },
            })
        }

        // Resize on font load
        // document.fonts.ready.then(() => this.resize())

        // Window event
        window.addEventListener('resizeEnd', this.onResize = () => this.resize())
    },
    computed: {
        lines() {
            return this.type === 'lines'
        },
        chars() {
            return this.type === 'chars'
        },
        splitType() {
            return this.lines ? 'lines, words' : 'lines, words, chars'
        },
        className() {
            let classname = `${CLASSNAME} -${this.type}`

            if(this.isVisible) {
                classname += ' is-visible'
            }

            return classname
        }
    },
    methods: {
        initSplits() {
            // Split text
            this.split = new SplitText(this.$el, {
                type: this.splitType,
                tag: 'span',
                linesClass: `${CLASSNAME}_line`,
                wordsClass: `${CLASSNAME}_word`,
                charsClass: `${CLASSNAME}_char`,
            })

            // Add number of lines props to container
            const totalLines = this.split.lines.length
            this.styles = `--at-lines-count: ${totalLines};`

            // Add line index to each lines
            this.split.lines.forEach(($line, i) => {
                $line.style.setProperty('--at-lines-index', i)
            })

            // Add chars index to each char
            const totalChars = this.split.chars.length
            if(totalChars > 0) {
                this.styles += ` --at-chars-count: ${totalChars};`
                this.split.chars.forEach(($char, i) => {
                    $char.style.setProperty('--at-chars-index', i)
                })
            }
        },

        revertSplits() {
            this.split.revert()
        },

        resize() {
            this.revertSplits()

            this.$nextTick(() => {
                this.initSplits()

                if(this.reveal) {
                    this.scrollTrigger.refresh(true)
                }
            })
        },

        show() {
            this.isVisible = true
        },

        hide() {
            this.isVisible = false
        }
    },
    watch: {
        visible(visible) {
            if(visible) {
                this.show()
            } else {
                this.hide()
            }
        }
    },
    unmounted() {
        if(this.reveal) {
            this.scrollTrigger.kill()
        }

        window.removeEventListener('resizeEnd', this.onResize)
    }
}

</script>

<style lang="scss">

.o-at {
    --at-lines-count: 1;
    --at-lines-index: 0;
    --at-chars-count: 1;
    --at-chars-index: 0;

    --at-duration-in: .8s;
    --at-easing-in: #{$easing};
    --at-delay-in: 0s;

    --at-duration-out: calc(.5 * var(--at-duration-in));
    --at-easing-out: #{$easing};
    --at-delay-out: 0s;

    display: inline-block;

    // Lines
    &.-lines {

        .o-at_word {
            transform: translate(0, 110%);
            will-change: transform;

            transition-property: transform;
            transition-duration: var(--at-duration-out);
            transition-timing-function: var(--at-easing-out);
            transition-delay: calc(var(--at-duration-out)/2 * ((var(--at-lines-count) - var(--at-lines-index))/var(--at-lines-count)) + var(--at-delay-out));
        }

        .o-text_separator.o-icon svg {
            opacity: 0;
            transform: translate(-110%, 0);

            transition-property: opacity, transform;
            transition-timing-function: var(--at-easing-out);
            transition-duration: var(--at-duration-out);
            transition-delay: var(--at-delay-out);
        }
    }

    // Characters
    &.-chars {

        .o-at_char {
            transform: translate(0, 110%);
            will-change: transform;

            transition-property: transform;
            transition-timing-function: var(--at-easing-out);
            transition-duration: var(--at-duration-out);
            transition-delay: calc(var(--at-duration-out)/2 * (var(--at-chars-index)/var(--at-chars-count)) + var(--at-delay-out));
        }
    }

    .c-loader &.is-visible,
    html.is-ready &.is-visible {

        // Lines
        &.-lines {

            .o-at_word {
                transform: translate(0);

                transition-timing-function: var(--at-easing-in);
                transition-duration: var(--at-duration-in);
                transition-delay: calc(var(--at-duration-in)/2 * (var(--at-lines-index)/var(--at-lines-count)) + var(--at-delay-in));
            }

            .o-text_separator.o-icon svg {
                opacity: 1;
                transform: translate(0);

                transition-timing-function: var(--at-easing-in);
                transition-duration: var(--at-duration-in);
                transition-delay: calc(var(--at-duration-in)/4 + var(--at-delay-in));
            }
        }

        // Characters
        &.-chars {

            .o-at_char {
                transform: translate(0);

                transition-timing-function: var(--at-easing-in);
                transition-duration: var(--at-duration-in);
                transition-delay: calc(var(--at-duration-in)/2 * (var(--at-chars-index)/var(--at-chars-count)) + var(--at-delay-in));
            }
        }
    }
}

.o-at_line {
    overflow: hidden;
}

.o-at_word {
}

.o-at_char {
    display: inline-block !important;
}

</style>
