/**
 *
 * Data content
 *
**/

export const assets = {
    video: 'https://d15w4a9uuuhkr6.cloudfront.net/assets/video/bg.mp4',
    audio: {
        ambiance: 'https://d15w4a9uuuhkr6.cloudfront.net/assets/audio/ambiance.mp3',
        hover: 'https://d15w4a9uuuhkr6.cloudfront.net/assets/audio/hover.mp3',
        pongPoint: 'https://d15w4a9uuuhkr6.cloudfront.net/assets/audio/pong-point.mp3',
        pongPaddle: 'https://d15w4a9uuuhkr6.cloudfront.net/assets/audio/pong-paddle.mp3',
        pongOut: 'https://d15w4a9uuuhkr6.cloudfront.net/assets/audio/pong-out.mp3',
        pongWin: 'https://d15w4a9uuuhkr6.cloudfront.net/assets/audio/pong-win.mp3',
        pongLose: 'https://d15w4a9uuuhkr6.cloudfront.net/assets/audio/pong-lose.mp3',
    }
}

export const general = {
    shopUrl: 'https://www.drdabber.com'
}

export const meta = {
    title: 'Dr. Dabber',
    description: `Award winning creators of the most reliable, high quality, best vape pens, wax pens, and dab pens. Inventors of the Boost eRig, the world's first portable electric dab rig and the SWITCH®, the most sophisticated dual use vaporizer on earth. Elevate your vaporizing experience with Dr. Dabber.`
}

export const products = [
    {
        id: 'switch',
        name: 'Switch®',
        price: 374.95,
        desc: 'The SWITCH® is a revolutionary induction heating vaporizer for both oils and flowers',
        intro: 'The SWITCH® is a revolutionary, patent-pending induction heating vaporizer for both oils and flowers. With the press of a button, you can transform the SWITCH® from a full fledged oil rig into an electric flower vaporizer.',
        url: 'https://www.drdabber.com/collections/switch',
        collection: {
            shopifyHandle: 'switch',
            shopifyProductIds: ['1444053450825', '1873555030089', '4350116003913', '4538093600841', '6585913868361', '774610780266', '9220780366'],
            title: 'The Switch® Collection',
            items: []
        },
        videoCover: {
            portrait: {
                src: 'https://d15w4a9uuuhkr6.cloudfront.net/switch/videos/long_4x5.mp4',
                width: 4,
                height: 5
            },
            landscape: {
                src: 'https://d15w4a9uuuhkr6.cloudfront.net/switch/videos/long_16x9.mp4',
                width: 16,
                height: 9
            }
        },
        heading: {
            title: [
                'Oil&nbsp;to',
                'Flower',
                'in',
                'Five',
                'Seconds',
            ],
            desc: 'Spearheading the induction heating movement, we’ve designed the Switch® with everything you would want in a dual use vaporizer. Say goodbye to replacing your atomizers. Welcome to the Dr. Dabber Switch®.',
            video: {
                portrait: {
                    src: 'https://d15w4a9uuuhkr6.cloudfront.net/switch/videos/short-1_4x5.mp4',
                    width: 4,
                    height: 5
                },
                landscape: {
                    src: 'https://d15w4a9uuuhkr6.cloudfront.net/switch/videos/short-1_16x9.mp4',
                    width: 16,
                    height: 9
                }
            }
        },
        video1: {
            portrait: {
                src: 'https://d15w4a9uuuhkr6.cloudfront.net/switch/videos/short-2_4x5.mp4',
                width: 4,
                height: 5
            },
            landscape: {
                src: 'https://d15w4a9uuuhkr6.cloudfront.net/switch/videos/short-2_16x9.mp4',
                width: 16,
                height: 9
            }
        },
        video2: {
            src: 'https://d15w4a9uuuhkr6.cloudfront.net/switch/videos/short-3_4x5.mp4',
            width: 4,
            height: 5
        },
        text1: 'Switch® from flower to oil instantly with the Switch’s patent-pending induction heating technology. With 25 different heat settings to choose from, there’s something for everyone and with over 100 sessions on a single charge plus pass through charging.',
        text2: 'The Switch® is sure to keep up with any lifestyle. Versatility is the Switch’s specialty. The future is here.',
        objectPath: '/3d/switch.glb',
        sequence: {
            path: 'https://d15w4a9uuuhkr6.cloudfront.net/switch/images/sequence/',
            length: 180,
            highlights: [
                {
                    title: 'Advanced',
                    desc: 'a revolutionary induction heating vaporizer for both oils and flowers',
                    start: 60,
                    end: 120
                },
                {
                    title: 'Ready in 5s',
                    desc: 'Heats to target temperature in under 5 seconds on average',
                    start: 140,
                    end: 180
                }
            ]
        },
        features: [
            {
                title: 'The Power & Speed of Induction',
                desc: 'Our patent-pending induction technology enables the Switch® to achieve temp in under five seconds. We encased all electronics within the glass to allow for simple cleaning and carefree use. Simply fill the well with isopropyl alcohol, wiping clean to remove all residues from inside. Since the device is sealed off, no oil will EVER reach into the mechanics, causing unit failure. We back this up with a 2-Year warranty on every Switch® purchased.',
                image: {
                    src: '/img/layout/switch/switch-feature-1.jpg',
                    width: 862,
                    height: 974
                }
            },
            {
                title: 'A Battery Built to Last',
                desc: 'With over 100 uses on a single charge, the Switch® can last through any day. For the average user, this means a whole week before having to stop and recharge! Dead battery got you down? Plug in your Switch® to keep the session going with the convenience of pass-through charging. With only 60 minutes to a full charge, you’ll be back to dabbing in no time at all.',
                image: {
                    src: '/img/layout/switch/switch-feature-2.jpg',
                    width: 862,
                    height: 862
                }
            },
            {
                title: 'Consistent Power',
                desc: 'Take control over your consumption without sacrificing performance or power. The Switch® is consistent enough to vaporize top-tier solventless extracts with no terpene degradation and powerful enough to run through any dried leaf materials. With just the flick of a switch®, you can elevate your unit from a top-tier flower vaporizer to a concentrate consumption powerhouse.',
                image: {
                    src: '/img/layout/switch/switch-feature-3.jpg',
                    width: 862,
                    height: 862
                }
            }
        ]
    },
    {
        id: 'boost-evo',
        name: 'Boost Evo®',
        price: 299.95,
        desc: 'The Boost Evo® was designed with one goal in mind: the perfect dab',
        intro: 'The Boost Evo® was designed from the ground up, incorporating proprietary technology, with one goal in mind: the perfect dab. For the ultimate dabbing experience, the Evo is your one stop shop.',
        url: 'https://www.drdabber.com/collections/boost-evo',
        collection: {
            shopifyHandle: 'boost-evo',
            shopifyProductIds: ['4563473432649', '6587669151817', '6598321635401', '4583311343689', '6599920091209', '6581676867657', '9220780366'],
            title: 'The Boost Evo® Collection',
            items: []
        },
        videoCover: {
            portrait: {
                src: 'https://d15w4a9uuuhkr6.cloudfront.net/boost-evo/videos/long_4x5.mp4',
                width: 4,
                height: 5
            },
            landscape: {
                src: 'https://d15w4a9uuuhkr6.cloudfront.net/boost-evo/videos/long_16x9.mp4',
                width: 16,
                height: 9
            }
        },
        heading: {
            title: [
                'Quartz',
                'to',
                'Glass',
                'Vapor',
                'Pathway'
            ],
            desc: 'Through our patented technology, high-quality melts are not diluted through a metal or silicone pathway. Taste your terps like never before, with Boost Evo’s quartz to glass vapor pathway.',
            video: {
                portrait: {
                    src: 'https://d15w4a9uuuhkr6.cloudfront.net/boost-evo/videos/short-1_4x5.mp4',
                    width: 4,
                    height: 5
                },
                landscape: {
                    src: 'https://d15w4a9uuuhkr6.cloudfront.net/boost-evo/videos/short-1_16x9.mp4',
                    width: 16,
                    height: 9
                }
            }
        },
        video1: {
            portrait: {
                src: 'https://d15w4a9uuuhkr6.cloudfront.net/boost-evo/videos/short-2_4x5.mp4',
                width: 4,
                height: 5
            },
            landscape: {
                src: 'https://d15w4a9uuuhkr6.cloudfront.net/boost-evo/videos/short-2_16x9.mp4',
                width: 16,
                height: 9
            }
        },
        video2: {
            src: 'https://d15w4a9uuuhkr6.cloudfront.net/boost-evo/videos/short-3_4x5.mp4',
            width: 4,
            height: 5
        },
        text1: 'For the ultimate dabbing experience, the Boost Evo® is your one stop shop. Its temperature control and ease of use will make the Evo your everyday concentrate rig.',
        text2: 'Utilizing the patented temperature control system, you can dial in your session, relying on your Evo to give a consistent experience every time.',
        objectPath: '/3d/boost-evo.glb',
        sequence: {
            path: 'https://d15w4a9uuuhkr6.cloudfront.net/boost-evo/images/sequence/',
            length: 152,
            highlights: [
                {
                    title: 'Powerful',
                    desc: 'A revolutionary portable electric dab rig unlike anything else',
                    start: 55,
                    end: 120
                },
                {
                    title: '6 heat settings',
                    desc: '6 carefully calibrated heat settings created with feedback from thousands of users',
                    start: 130,
                    end: 152
                }
            ]
        },
        features: [
            {
                title: 'Heat Settings Designed By Dabbers',
                desc: 'Our goal with the Boost Evo® was to design an e-rig that could achieve the perfect dab every single time. Featuring a patented temperature control sensor built directly into the atomizer, the Boost Evo® consistently delivers just as we had planned. With six heat settings specifically calibrated by Dr. Dabber’s expert team and based on feedback from thousands of users, the Boost Evo® is sure to satisfy both newcomers and dab connoisseurs.',
                image: {
                    src: '/img/layout/boost-evo/boost-evo-feature-1.jpg',
                    width: 862,
                    height: 974
                }
            },
            {
                title: 'A Revolutionary Atomizer You Can Rely On',
                desc: 'Boost Evo® features a floating atomizer with a built-in temperature sensor and heating element, allowing for direct heat to be sent to the vaporization cup, resulting in reduced heating times and improved temperature control. Using only the highest quality materials, the Boost Evo’s atomizer is one you can rely on, lasting 8-12 months even with heavy usage. Boost Evo’s atomizer is revolutionary in the cannabis industry, and truly one-of-a-kind.',
                image: {
                    src: '/img/layout/boost-evo/boost-evo-feature-2.jpg',
                    width: 862,
                    height: 974
                }
            }
        ],
        shopDesc: 'The Boost Evo® is a revolutionary, portable electric dab rig. Packed with tons of features like rapid heat up time, straight quartz to glass vapor pathway, RGB lights, extended hold time, pass through charging, and more, the Boost Evo® is your one stop shop for a portable eRig.'
    },
    {
        id: 'stella',
        name: 'Stella®',
        price: 99,
        desc: 'The Stella® is the new standard for a premium vaporizer pen',
        intro: 'The Stella® is the new standard for a premium vaporizer pen, expertly packed into our signature black stainless steel body and designed with top tier features… Prepare for liftoff.',
        url: 'https://www.drdabber.com/collections/stella',
        collection: {
            shopifyHandle: 'stella',
            shopifyProductIds: ['4513147224137', '4531780616265', '4519155433545', '4519155269705', '9220780366', '4519155695689'],
            title: 'The Stella® Collection',
            items: []
        },
        videoCover: {
            portrait: {
                src: 'https://d15w4a9uuuhkr6.cloudfront.net/stella/videos/long_4x5.mp4',
                width: 4,
                height: 5
            },
            landscape: {
                src: 'https://d15w4a9uuuhkr6.cloudfront.net/stella/videos/long_16x9.mp4',
                width: 16,
                height: 9
            }
        },
        heading: {
            title: [
                'Experience',
                'Flavor',
                'Beyond',
                'This',
                'World',
            ],
            desc: 'Stella’s atomizer is long-lasting and designed with a TCR heating element to provide a constant temperature through the heating process and ensuring maximum flavor with minimal waste.',
            video: {
                portrait: {
                    src: 'https://d15w4a9uuuhkr6.cloudfront.net/stella/videos/short-1_4x5.mp4',
                    width: 4,
                    height: 5
                },
                landscape: {
                    src: 'https://d15w4a9uuuhkr6.cloudfront.net/stella/videos/short-1_16x9.mp4',
                    width: 16,
                    height: 9
                }
            }
        },
        video1: {
            portrait: {
                src: 'https://d15w4a9uuuhkr6.cloudfront.net/stella/videos/short-2_4x5.mp4',
                width: 4,
                height: 5
            },
            landscape: {
                src: 'https://d15w4a9uuuhkr6.cloudfront.net/stella/videos/short-2_16x9.mp4',
                width: 16,
                height: 9
            }
        },
        video2: {
            src: 'https://d15w4a9uuuhkr6.cloudfront.net/stella/videos/short-3_4x5.mp4',
            width: 4,
            height: 5
        },
        text1: 'Crafted out of black stainless steel for an elegant, yet durable look and feel, the Stella® sets the standard for a premium vaporizer pen. Featuring three heat settings and a pre-heat mode, the Stella® can handle any type of concentrate.',
        text2: 'Stella’s atomizer is fully ceramic with no exposed coils, fitted with a floating vaporization chamber to optimize performance. The Stella® is what discreet dabbing on-the-go was always meant to be.',
        objectPath: '/3d/stella.glb',
        sequence: {
            path: 'https://d15w4a9uuuhkr6.cloudfront.net/stella/images/sequence/',
            length: 150,
            highlights: [
                {
                    title: 'Premium',
                    desc: 'The new standard for a premium vaporizer pen',
                    start: 45,
                    end: 100
                },
                {
                    title: 'Ready in Seconds',
                    desc: 'Heats to target temperature and remains consistent through each and every hit',
                    start: 120,
                    end: 150
                }
            ]
        },
        features: [
            {
                title: 'Temperature Controlled for Consistency',
                desc: 'Stella® comes with a built-in alumina ceramic vaporization chamber with a sealed alumina ceramic TCR heating element. Instead of outputting a constant voltage, Stella’s TCR heating element allows the voltage to adjust based on the desired temperature selected. Benefits of temperature control include avoiding dry or burnt hits, preservation of the atomizer, extended battery life, and overall vape consistency.',
                image: {
                    src: '/img/layout/stella/stella-feature-1.jpg',
                    width: 862,
                    height: 862
                }
            },
            {
                title: 'Upgraded Vortex Airflow',
                desc: 'Stella® features a vortex-like airflow functionality, optimizing flavor and vapor production, due to the offset air inlets in the vaporization chamber. We’ve also upgraded this pen with a floating vaporization chamber to optimize performance. The floating chamber reduces the contact of the chamber with the rest of the body of the pen, reducing heat transfer to the user’s hands and helps focus heat to the vaporization chamber.',
                image: {
                    src: '/img/layout/stella/stella-feature-2.jpg',
                    width: 862,
                    height: 974
                }
            }
        ],
        shopDesc: 'Designed with three heat settings plus a preheat setting, the Stella® offers a temperature for everyone. Stella® atomizers are built to last and designed to provide maximum flavor with minimal waste. This is the next generation premium vaporizer pen.'
    }
]

export default {
    assets,
    general,
    meta,
    products
}
