<template>
    <span :class="className">
        <svg
            :class="`svg-${name}`"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            :aria-labelledby="title ? `${name}-label` : undefined"
        >
            <title
                v-if="title"
                :id="`${name}-label`"
            >
                {{ title }}
            </title>
            <use :xlink:href="path" xmlns:xlink="http://www.w3.org/1999/xlink"/>
        </svg>
    </span>
</template>

<script>

export default {
    name: 'Icon',
    props: {
        name: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: null
        }
    },
    computed: {
        path() {
            let icon = require(`@/assets/svg/${this.name}.svg`);
            if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
                icon = icon.default;
            }
            return icon.url;
        },

        className() {
            return `o-icon -${this.name}`
        }
    }
}

</script>

<style lang="scss">

/*----------  Global styles for icones  ----------*/

.o-icon {
    display: inline-block;

    svg {
        display: block;
        width: var(--svg-width);
        height: calc(var(--svg-width) * var(--svg-ratio));
    }
}


/*----------  SVG sizes  ----------*/

.svg-logo-text {
    --svg-width: #{rem(98px)};
    --svg-ratio: 44/292;
}

.svg-logo-icon {
    --svg-width: #{rem(26px)};
    --svg-ratio: 39/22;
}

.svg-half-circles {
    --svg-width: .9em;
    --svg-ratio: 1;
}

.svg-plus {
    --svg-width: 1em;
    --svg-ratio: 1;
}

// Sound
.svg-sound-on,
.svg-sound-off {
    --svg-width: #{rem(16px)};
    --svg-ratio: 10/9;
}

// Socials
.svg-social-discord,
.svg-social-twitter,
.svg-social-youtube,
.svg-social-linkedin,
.svg-social-facebook,
.svg-social-instagram {
    --svg-width: #{rem(26px)};
    --svg-ratio: 1;
}

</style>
