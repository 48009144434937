<template>
    <header
        class="c-header"
        :class="{ 'is-reduced' : isReduced }"
    >
        <div class="c-header_inner">
            <router-link
                class="c-header_logo"
                to="/"
                @mouseenter="setHover(true)"
                @mouseleave="setHover(false)"
            >
                <icon
                    name="logo-text"
                    title="Dr. Dabber Home"
                />
            </router-link>
            <nav
                class="c-header_nav | c-nav"
                @mouseenter="setHover(true)"
                @mouseleave="setHover(false)"
            >
                <ul class="c-nav_list">
                    <li
                        v-for="product in store.state.products"
                        :key="`header-nav-${product.id}`"
                        class="c-nav_item"
                    >
                        <router-link
                            :to="`/product/${product.id}`"
                            class="c-nav_link"
                            v-hover-sound
                        >
                            {{ product.name }}
                        </router-link>
                    </li>
                </ul>
            </nav>
            <app-button
                iconLabel="Toggle Sound"
                :icon="soundIcon"
                :sound="false"
                class="c-header_sound"
                @click="toggleSound()"
            />
            <app-button
                tag="a"
                :url="shopUrl"
                target="_blank"
                rel="noopener noreferrer"
                label="Shop now"
                arrow="right"
                class="c-header_button"
                @mouseenter="setHover(true)"
                @mouseleave="setHover(false)"
            />
            <router-link
                to="/"
                class="c-header_icon"
                @mouseenter="setHover(true)"
                @mouseleave="setHover(false)"
            >
                <icon
                    name="logo-icon"
                    title="Dr. Dabber Home"
                />
            </router-link>
        </div>
    </header>
</template>

<script>

import Icon             from '@/templates/objects/Icon'
import AppButton        from '@/templates/components/AppButton'

export default {
    name: 'AppHeader',
    inject: ['store'],
    components: {
        Icon,
        AppButton,
    },
    data: () => ({
        isReduced: false,
        isHovering: false,
        previousScroll: 0,
        isHomeOnMobile: false,
    }),
    mounted() {
        window.addEventListener('scroll', this.onScroll.bind(this))
    },
    methods: {
        setHover(hovering) {

            if(this.isHomeOnMobile) {
                return
            }

            this.isHovering = hovering

            if(hovering) {
                this.isReduced = false
            }
        },
        onScroll() {

            if(this.isHomeOnMobile) {
                return
            }

            const y = window.scrollY

            // Show/hide
            if(y > this.previousScroll && !this.isReduced && !this.isHovering) {
                this.isReduced = true
            } else if(y < this.previousScroll && this.isReduced) {
                this.isReduced = false
            }

            this.previousScroll = y
        },
        toggleSound() {
            this.store.toggleSound()
        }
    },
    computed: {
        shopUrl() {
            return this.store.shopUrl
        },
        soundIcon() {
            return this.store.hasSoundOn ? 'sound-on' : 'sound-off'
        }
    },
}

</script>

<style lang="scss">

.c-header {

    &.is-reduced,
    html.is-loading & {

        .c-nav_link {
            transform: translate(0, 100%);
        }

        .c-header_sound,
        .c-header_button {
            opacity: 0;
        }
    }
}

.c-header_inner {
}

.c-header_nav,
.c-header_logo,
.c-header_icon,
.c-header_sound,
.c-header_button {
    z-index: 100;
    position: fixed;
    top: var(--grid-gutter);
}

.c-header_logo {
    left: var(--grid-gutter);
    display: inline-block;
    overflow: hidden;

    .o-icon {
        display: block;
        transition: transform $speed-slow $easing $speed;
    }

    html:not(.-mode-primary) & {
        mix-blend-mode: difference;
    }

    html.-firstload & {

        .o-icon {
            transform: translate(0, 100%);
        }
    }
}

.c-header_nav {

    html:not(.-mode-primary) & {
        mix-blend-mode: difference;
    }

    @media (max-width: $to-tiny) {
        left: grid-space(4/10, 2);
    }

    @media (min-width: $from-tiny) and (max-width: $to-small) {
        left: grid-space(3/10, 2);
    }

    @media (min-width: $from-small) {
        left: grid-space(4/16, 2);
    }
}

.c-header_sound {
    transition: opacity $speed $easing;

    @media (max-width: $to-small) {
        left: calc(#{grid-space(7/10, 2)});
    }

    @media (min-width: $from-small) {
        left: calc(#{grid-space(12/16, 1)} - var(--button-height));
    }
}

.c-header_button {
    left: grid-space(12/16, 2);
    transition: opacity $speed $easing;

    @media (max-width: $to-small) {
        display: none;
        visibility: hidden;
    }

    @media (max-width: $to-medium) {
        min-width: grid-space(3/16);
    }

    @media (min-width: $from-medium) {
        min-width: grid-space(2/16);
    }
}

.c-header_icon {
    right: var(--grid-gutter);
    display: inline-block;
    transform-origin: 50% 100%;
    transition: opacity $speed-slow $easing $speed-slow, transform $speed-slow $easing $speed-slow;

    .o-icon {
        display: block;

        html:not(.-firstload).is-loading & {
            animation: anim-header-icon-load 1s $easing infinite 1s;
        }
    }

    html:not(.-mode-primary) & {
        mix-blend-mode: difference;
    }

    html.-firstload & {
        opacity: 0;
        transform: translate(0, 50%) rotateY(90deg) scale(.5);
    }
}


/*----------  Navigation  ----------*/

.c-nav {
    font-weight: 500;
    color: $color-gray;
}

.c-nav_list {

    &:hover {

        .c-nav_link:not(:hover) {
            color: rgba($color-lightest, .2);

            html.-mode-primary & {
                color: rgba($color-dark, .3);
            }
        }
    }
}

.c-nav_item {
    overflow: hidden;
    margin-left: -1em;
    padding: 1px 1px 1px 1em;
}

.c-nav_link {
    display: block;
    color: var(--html-color-text);
    transition: color $speed $easing, opacity $speed $easing, transform $speed-slow $easing;

    &.is-current {

        &:before {
            content: "\2192";
            position: absolute;
            bottom: 0;
            right: calc(100% + .25em);
        }
    }

    &:focus-visible {
        opacity: .7;
    }

    .page-home.-layout-mobile & {
        transform: translate(0, 100%);
    }
}


/*----------  Animations  ----------*/

@keyframes anim-header-icon-load {
    0% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0);
        opacity: 1;
    }
    50% {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0, 0 0);
        opacity: 1;
    }
    50.01% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0);
        opacity: 0;
    }
    100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0);
        opacity: 1;
    }
}

@keyframes anim-header-icon-load-mask {
    0% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
    50%,
    99% {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0, 0 0);
    }
    99.0000001%,
    100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
}

@keyframes anim-header-icon-load-fade {
    0%,
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


</style>
