import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import debounce from './utils/debounce'
import hoverSound from './directives/hover-sound'

// Add resizeEnd event to window
const resizeEndEvent = new CustomEvent('resizeEnd');
window.addEventListener('resize', debounce(() => {
    window.dispatchEvent(resizeEndEvent)
}, 200, false))

// Create app
const app = createApp(App)
app.use(router)
app.directive('hover-sound', hoverSound)


// Mount
app.mount('#app')
