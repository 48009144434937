<template>
    <component
        :is="tag"
        :to="isRouterLink ? url : null"
        :href="isLink ? url : null"
        class="c-button"
        :class="modifiers"
        v-hover-sound="sound"
    >
        <span class="c-button_inner">
            <span
                v-if="label !== null || !!$slots.default"
                class="c-button_label"
            >
                <slot>{{ label }}</slot>
            </span>
            <icon
                v-if="icon !== null"
                class="c-button_icon"
                :name="icon"
                :title="iconLabel"
            />
            <span
                v-else-if="arrow !== null"
                class="c-button_icon -arrow"
                :class="`-${arrow}`"
            ></span>
        </span>
    </component>
</template>

<script>

import Icon from '@/templates/objects/Icon'

export default {
    name: 'AppButton',
    inject: ['store'],
    components: {
        Icon
    },
    props: {
        tag: {
            type: String,
            default: 'button'
        },
        display: {
            type: String,
            default: null
        },
        stretch: {
            type: Boolean,
            default: false
        },
        url: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        arrow: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        iconLabel: {
            type: String,
            default: null
        },
        sound: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        isRouterLink() {
            return this.tag === 'router-link'
        },
        isLink() {
            return this.tag === 'a'
        },
        modifiers() {
            let modifiers = ''

            if(this.display !== null) {
                modifiers += `-${this.display}`
            }

            if(this.stretch) {
                modifiers += ' -stretch'
            }

            if(this.label === null) {
                modifiers += ' -icon'
            }

            return modifiers
        }
    }
}

</script>

<style lang="scss">

.c-button {
    --button-height: #{rem(40px)};

    display: inline-flex;
    align-items: center;
    height: var(--button-height);
    padding-right: rem(12px);
    padding-left: rem(12px);

    color: $color-primary;
    border-radius: $radius-tiny;
    cursor: pointer;
    transition: color $speed $easing;

    &:after,
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }

    &:before {
        box-shadow: 0 0 32px 0 rgba($color-primary, 0.8);
        opacity: 0;
        transition: opacity $speed $easing;
    }

    &:after {
        background-color: $color-dark;
        transition: background-color $speed ease-out;
    }

    // Displays
    &.-primary {
        color: $color-dark;

        &:after {
            background-color: $color-primary;
        }
    }

    &.-darkest {

        &:after {
            background-color: $color-darkest;
        }
    }

    &.-border {
        border-radius: 50%;

        &:after {
            background-color: transparent;
            border: 1px solid currentColor;
        }
    }

    // Stretch
    &.-stretch {

        .c-button_label {
            margin-right: auto;
            margin-left: auto;
            padding-right: 0;
            padding-left: 0;
        }
    }

    // Icon
    &.-icon {
        width: var(--button-height);
        padding-right: 0;
        padding-left: 0;

        .c-button_inner {
            justify-content: center;
        }

        .c-button_icon {
            margin-left: 0;
        }
    }

    &:hover {
        color: $color-darkest;
        transition: color $speed-long $easing;

        &:before {
            opacity: 1;
            transition: opacity $speed-long $easing;
            animation: anim-button-pulse 2s ease-in-out infinite $speed-long;
        }

        &:after {
            background-color: $color-primary;
            transition: background-color $speed-slow ease-out;
        }

        .c-button_label {
            animation: anim-button-label $speed-long $easing;
        }

        .c-button_icon {

            &.-arrow.-right {
                transform: translate(-.2em, 0);
                transition: transform $speed-long $easing;
            }

            &.-arrow.-down {
                transform: translate(0, .1em);
                transition: transform $speed-long $easing;
            }
        }
    }

    &:focus-visible {
        opacity: .7;
    }
}

.c-button_inner {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
}

.c-button_label {
    line-height: 1;
    font-size: rem(12px);
    font-weight: 700;
    text-transform: uppercase;
    white-space: nowrap;

    &:only-child {
        padding-right: 1.5em;
        padding-left: 1.5em;
    }
}

.c-button_icon {
    margin-left: rem(20px);
    line-height: .8;
    font-size: rem(18px);
    transition: transform $speed $easing;

    &.-arrow.-right {

        &:before {
            content: "\2192";
        }
    }

    &.-arrow.-down {

        &:before {
            content: "\2193";
        }
    }

    &.-arrow.-left {

        &:before {
            content: "\2190";
        }
    }
}

/*----------  Animations  ----------*/

@keyframes anim-button-label {
    0% {
        transform: translate(0);
    }
    30% {
        transform: translate(0, -100%);
    }
    30.00001% {
        transform: translate(0, 100%);
    }
    100% {
        transform: translate(0);
    }
}

@keyframes anim-button-pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

</style>
