import { store }        from '@/store'
import { assets }       from '@/data'
import { loadAudio }    from '@/utils/audio'

const hoverSound = {
    created($el, binding) {
        $el.isDisabled = binding.value === false
    },
    mounted($el) {

        if($el.isDisabled) {
            return
        }

        console.log(assets.audio.hover)
        const audio = loadAudio(assets.audio.hover, .1)

        $el.onMouseEnter = () => {
            if(store.hasSoundOn) {
                audio.play()
            }
        }

        $el.addEventListener('mouseenter', $el.onMouseEnter)
    },
    beforeUnmounted($el) {

        if($el.isDisabled) {
            return
        }

        $el.removeEventListener('mouseenter', $el.onMouseEnter)
    }
}

export default hoverSound
